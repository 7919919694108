<template>
	<div class="confirmorder">
		确认订单
		<div class="ordercon">
			<el-checkbox v-model="checked" @change="xieyi">接受山东泰克莱恩<a href="javascript:void(0)" @click="dialogTableVisible = true">&lt;&lt;使用条款及隐私政策&gt;&gt;</a></el-checkbox>
			<div>
				<el-button :disabled="btnIsDisabled" type="button" @click="submit">确认提交</el-button>
			</div>
		</div>
		<el-dialog
		  title="提示"
		  :visible.sync="dialogTableVisible"
		  width="50%"
		  >
		  <span>{{protocolCon}}</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogTableVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogTableVisible = false">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		data:function(){
			return{
				checked:false,
				btnIsDisabled:true,
				protocolCon:'',
				dialogTableVisible:false,
				ruleForm:{
					oid:"0"
				}
			}
		},
		mounted:function(){
			//设置步骤条颜色
			this.$store.commit('setStepIndex', 8);
			//设置默认地址
			sessionStorage.setItem("location","ordereight");
			//获取oid的值
			let oid=sessionStorage.getItem("oid");
			this.ruleForm.oid=oid;
			//调用读取协议的方法
			this.getProCon();
		},
		methods:{
			//读取协议文本文件
			getProCon(){
			  this.$axios.get("http://localhost:8080/protocol.txt").then(res=>{
				  console.log(res);
				  this.protocolCon=res.data
			  }).catch(err=>{
				  console.log(err);
			  });	
			},
			//勾选协议时触发的事件
			xieyi(){
				console.log(this.checked);
				if(this.checked){
					console.log(1);
					this.btnIsDisabled=false;
				}else{
					this.btnIsDisabled=true;
				}
			},
			//确定提交按钮
			submit(){
				this.$axios.get("client/order/submitOrder",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					if(res.data.code==200){
						this.$router.push("/main");
					}
				}).catch(err=>{
					this.$message({
						message:"查看失败",
						type:"error",
						duration:900
					});
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.confirmorder {
		background-image: linear-gradient(to bottom, white, #9BEDEB, #28D9D5);
		height: 100%;
	}
	/* 放地址的大盒子 */
	.ordercon{
		width: 60%;
		height: 70%;
		margin: 0px auto;
		margin-top: 1.25rem;
		/* border: 1px solid red; */
		overflow-y: auto;
		border-radius: 0.9375rem;
		background-image: linear-gradient(to bottom,white,	#F2F2F2);
		box-shadow: 0px 0px 20px gray;
		padding: 0.625rem;
	}
	/* div水平的滚动条隐藏 */
	.ordercon::-webkit-scrollbar {
	           display: none;
	}
</style>
